import axios from "axios";
import qs from "qs";
export const getDataForDB = async ({ populate, filters, collection }) => {
  if (!populate && !filters) {
    return axios.get(`${getURL()}/api/${collection}`);
  } else if (populate && !filters) {
    if (populate === "*") {
      return axios.get(`${getURL()}/api/${collection}?populate=*`);
    } else {
      const query = qs.stringify({
        populate,
      });
      return axios.get(`${getURL()}/api/${collection}?${query}`);
    }
  } else if (!populate && filters) {
    const query = qs.stringify({
      filters,
    });
    return axios.get(`${getURL()}/api/${collection}?${query}`);
  } else if (populate && filters) {
    if (populate === "*") {
      const query = qs.stringify({
        filters,
      });
      return axios.get(`${getURL()}/api/${collection}?${query}&populate=*`);
    } else {
      const query = qs.stringify({
        filters,
        populate,
      });
      return axios.get(`${getURL()}/api/${collection}?${query}`);
    }
  }
};

//Push data to localstorage to track history of what content the user has been checking out.
export const pushToLocalStorage = (data) => {
  console.log("Trying to push to localstorage", data);
  let history = getHistoryFromLocalStorage();
  console.log("Got history,", history);
  if (!history) {
    history = [data];
  }
  if (history.length > 0) {
    console.log("History length is greater than 0", history);
    //check if data exists in history, if so then push data to the first index of the array
    const index = history.findIndex((item) => item.id === data.id);
    if (index !== -1) {
      history.splice(index, 1);
    }
    history.unshift(data);
    localStorage.setItem("history", JSON.stringify(history));
  } else {
    console.log("History length is 0", history);
    history.push(data);
    localStorage.setItem("history", JSON.stringify(history));
  }
};

export const getHistoryFromLocalStorage = () => {
  return JSON.parse(localStorage.getItem("history"));
};

export const getURL = () => {
  return "https://workshop-backend-9325f235aa22.herokuapp.com";
  return process.env.REACT_APP_CMS_URL === "localhost:1337"
    ? "http://localhost:1337"
    : "https://" + process.env.REACT_APP_CMS_URL;
};

export const getMediaURL = () => {
  return "";
  return process.env.REACT_APP_CMS_URL === "localhost:1337"
    ? "http://localhost:1337"
    : "";
};

export const pushUpdateEmail = (email, version) => {
  return axios.post(`${getURL()}/api/update-downloads`, {
    data: {
      email,
      version,
    },
  });
};
