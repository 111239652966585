import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { ReactComponent as HelpIcon } from "../../icons/help.svg";
import HeaderContainer from "../../Components/HeaderContainer";
import jsonData from "../../localdb.json";

export default function Tutorials() {
  const [lessons, setLessons] = useState(null);
  const [robots, setRobots] = useState(null);
  const [selectedRobot, setSelectedRobot] = useState(null);
  const [robotActive, setRobotActive] = useState(false);
  const [products, setProducts] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productActive, setProductActive] = useState(false);
  const [trackers, setTrackers] = useState(null);
  const [selectedTracker, setSelectedTracker] = useState(null);
  const [trackerActive, setTrackerActive] = useState(false);

  useEffect(() => {
    console.log("UseEffect");
    let trackers = [];
    let robots = [];
    let products = [];
    jsonData.tutorials.forEach((collection) => {
      //check if tracker is already in array
      if (collection.attributes.trackers.data[0]) {
        if (
          collection.attributes.trackers &&
          !trackers.find(
            (tracker) =>
              tracker.id === collection.attributes.trackers.data[0].id
          )
        ) {
          trackers.push(collection.attributes.trackers.data[0]);
        }
      }
      //check if robot is already in array
      if (collection.attributes.robots.data[0]) {
        if (
          collection.attributes.robots &&
          !robots.find(
            (robot) => robot.id === collection.attributes.robots.data[0].id
          )
        ) {
          robots.push(collection.attributes.robots.data[0]);
        }
      }

      //check if product is already in array
      if (collection.attributes.products.data[0]) {
        if (
          collection.attributes.products &&
          !products.find(
            (product) =>
              product.id === collection.attributes.products.data[0].id
          )
        ) {
          products.push(collection.attributes.products.data[0]);
        }
      }
    });
    setTrackers(trackers);
    setRobots(robots);
    setProducts(products);
    //Sort lessons by rank
    jsonData.tutorials.sort((a, b) => {
      return a.attributes.rank - b.attributes.rank;
    });
    setLessons(jsonData.tutorials);
  }, []);

  const closeAllModals = () => {
    setRobotActive(false);
    setProductActive(false);
    setTrackerActive(false);
  };

  const renderLessons = () => {
    const filteredLessons = lessons.filter((lesson) => {
      if (
        selectedProduct === "All" ||
        selectedProduct === null ||
        !selectedProduct
      ) {
        return true;
      } else {
        if (!lesson.attributes.product) return false;
        return (
          lesson.attributes.product.data.attributes.name === selectedProduct
        );
      }
    });
    const filteredLessons2 = filteredLessons.filter((lesson) => {
      if (selectedRobot === "All" || selectedRobot === null || !selectedRobot) {
        return true;
      } else {
        if (!lesson.attributes.robot) return false;
        return lesson.attributes.robot.data.attributes.name === selectedRobot;
      }
    });
    const filteredLessons3 = filteredLessons2.filter((lesson) => {
      if (
        selectedTracker === "All" ||
        selectedTracker === null ||
        !selectedTracker
      ) {
        return true;
      } else {
        if (!lesson.attributes.tracker) return false;
        return (
          lesson.attributes.tracker.data.attributes.name === selectedTracker
        );
      }
    });
    return filteredLessons3.map((lesson) => {
      console.log("Here", lesson);
      return (
        <SingleLesson
          key={lesson.attributes.name}
          to={`/tutorials/${lesson.attributes.slug}/${lesson.attributes.lessons[0].tutorials.data[0].attributes.slug}`}
        >
          <img
            src={lesson.attributes.image.data[0].attributes.url}
            alt={lesson.attributes.name}
          />
          <h3>{lesson.attributes.name}</h3>
          <p>{lesson.attributes.description}</p>
          <Tags>
            {lesson.attributes.product && (
              <Tag>
                <HelpIcon />
                <p>{lesson.attributes.product.data.attributes.name}</p>
              </Tag>
            )}

            {lesson.attributes.tracker && (
              <Tag>
                <HelpIcon />
                <p>{lesson.attributes.tracker.data.attributes.name}</p>
              </Tag>
            )}

            {lesson.attributes.robot && (
              <Tag>
                <HelpIcon />
                <p>{lesson.attributes.robot.data.attributes.name}</p>
              </Tag>
            )}
          </Tags>
        </SingleLesson>
      );
    });
  };
  return (
    <Container>
      {!products || !robots || !trackers || !lessons ? (
        <div>Loading...</div>
      ) : (
        <>
          <HeaderContainer>
            <Pagination>
              <Link href="/tutorials">Tutorials</Link>
            </Pagination>
            <h1>Welcome to Nordbo´s Academy</h1>
            <p>
              Use the form here to find the content for your product,
              <br />
              or navigate in the library down below.
            </p>
            {/* <MainQuestionContainer>
              <AroundquestionContent>
                <QuestionText>I Have</QuestionText>
                <AnswerContainer className={productActive ? "active" : ""}>
                  <InitialAnswer
                    onClick={() => {
                      closeAllModals();
                      setProductActive(!productActive);
                    }}
                  >
                    <QuestionText>
                      {selectedProduct ? selectedProduct : "Product"}
                    </QuestionText>
                  </InitialAnswer>
                  <ListOfAnswers className={productActive && "active"}>
                    <QuestionText
                      key={"All"}
                      onClick={() => {
                        setSelectedProduct("All");
                        closeAllModals();
                      }}
                    >
                      All
                    </QuestionText>
                    {products.map((product) => {
                      return (
                        <QuestionText
                          key={product.attributes.name}
                          onClick={() => {
                            setSelectedProduct(product.attributes.name);
                            closeAllModals();
                          }}
                        >
                          {product.attributes.name}
                        </QuestionText>
                      );
                    })}
                  </ListOfAnswers>
                </AnswerContainer>
              </AroundquestionContent>
              <AroundquestionContent>
                <QuestionText>With</QuestionText>
                <AnswerContainer className={trackerActive ? "active" : ""}>
                  <InitialAnswer
                    onClick={() => {
                      closeAllModals();

                      setTrackerActive(!trackerActive);
                    }}
                  >
                    <QuestionText>
                      {selectedTracker ? selectedTracker : "Tracker"}
                    </QuestionText>
                  </InitialAnswer>
                  <ListOfAnswers className={trackerActive && "active"}>
                    <QuestionText
                      key={"All"}
                      onClick={() => {
                        setSelectedTracker("All");
                        closeAllModals();
                      }}
                    >
                      All
                    </QuestionText>
                    {trackers.map((tracker) => {
                      return (
                        <QuestionText
                          key={tracker.attributes.name}
                          onClick={() => {
                            setSelectedTracker(tracker.attributes.name);
                            closeAllModals();
                          }}
                        >
                          {tracker.attributes.name}
                        </QuestionText>
                      );
                    })}
                  </ListOfAnswers>
                </AnswerContainer>
              </AroundquestionContent>
              <AroundquestionContent>
                <QuestionText>For</QuestionText>
                <AnswerContainer className={robotActive ? "active" : ""}>
                  <InitialAnswer
                    onClick={() => {
                      closeAllModals();
                      setRobotActive(!robotActive);
                    }}
                  >
                    <QuestionText className="blue">
                      {selectedRobot ? selectedRobot : "Robot"}
                    </QuestionText>
                  </InitialAnswer>
                  <ListOfAnswers className={robotActive && "active"}>
                    <QuestionText
                      key={"All"}
                      onClick={() => {
                        setSelectedRobot("All");
                        closeAllModals();
                      }}
                    >
                      All
                    </QuestionText>
                    {robots.map((robot) => {
                      return (
                        <QuestionText
                          key={robot.attributes.name}
                          onClick={() => {
                            setSelectedRobot(robot.attributes.name);
                            closeAllModals();
                          }}
                        >
                          {robot.attributes.name}
                        </QuestionText>
                      );
                    })}
                  </ListOfAnswers>
                </AnswerContainer>
              </AroundquestionContent>
              {(selectedProduct !== null ||
                selectedTracker !== null ||
                selectedRobot !== null) && (
                <ResetQuestions
                  onClick={() => {
                    setSelectedProduct(null);
                    setSelectedTracker(null);
                    setSelectedRobot(null);
                    closeAllModals();
                  }}
                >
                  reset
                </ResetQuestions>
              )}
            </MainQuestionContainer> */}
          </HeaderContainer>
          <LibraryContainer>
            <LibraryHeader>
              <h3>Pick a course</h3>
              <p>
                Here you can find all the content we have available for you.
                <br />
                Use the filters above to find the content you are looking for.
              </p>
            </LibraryHeader>
            <LibraryContent>{renderLessons()}</LibraryContent>
          </LibraryContainer>
        </>
      )}
    </Container>
  );
}

const Pagination = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  width: 100%;

  a {
    color: ${({ theme }) => theme.colors.text};
    font-weight: 400;
    text-decoration: none;
    margin: 0;
    padding: 0;
    &:hover {
      text-decoration: underline;
      color: ${({ theme }) => theme.colors.cta};
    }
  }
`;

const Container = styled.div``;

const MainQuestionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 40px;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-top: 24px;
    margin-bottom: 0px;

    h1 {
      font-size: 1.3rem;
      text-align: left;
    }
  }
`;

const QuestionText = styled.h1`
  font-size: 2.6rem;
  font-weight: 400;
  text-align: center;
  color: ${({ theme }) => theme.colors.ctextta};

  @media (max-width: 768px) {
    font-size: 1.6rem;
  }
`;
const ResetQuestions = styled.h1`
  font-size: 1.6rem;
  font-weight: 400;
  text-align: center;
  cursor: pointer;
  opacity: 0.8;
`;

const AnswerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-left: 5px;
  margin-right: 5px;

  @media (max-width: 768px) {
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 0;
  }
`;

const InitialAnswer = styled.div`
  border-bottom: 2px solid ${({ theme }) => theme.colors.cta};
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
  height: 50px;
  color: ${({ theme }) => theme.colors.cta};
  &:hover {
    opacity: 1;
    color: ${({ theme }) => theme.colors.cta};
  }
  @media (max-width: 768px) {
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    height: auto;
    margin-left: 0;
    h1 {
      text-align: left;
      margin-top: 8px;
      font-size: 1.3rem;
    }
  }
`;

const ListOfAnswers = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  position: absolute;
  top: 60px;
  left: 0;
  height: 0;
  background-color: ${({ theme }) => theme.colors.backgroundLighter};
  border-radius: 8px;
  box-shadow: 0px 7px 24px -10px rgba(2, 11, 28, 0.3);
  min-width: 300px;
  z-index: 1;
  h1 {
    display: none;
    text-align: left;
    margin-top: 16px;
    font-size: 1.4rem;

    &:hover {
      color: ${({ theme }) => theme.colors.cta};
      text-decoration: underline;
    }
  }
  cursor: pointer;

  &.active {
    height: auto;
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 32px;
    padding-top: 18px;
    h1 {
      display: block;
    }
  }
`;

const LibraryContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  @media (max-width: 768px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const LibraryHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 32px;
  text-align: left;

  h3 {
    margin: 0;
    padding: 0;
    margin-bottom: 8px;
    font-size: 1.6rem;
  }
  p {
    opacity: 0.7;
  }
`;

const LibraryContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 32px;
  padding-bottom: 32px;
  text-align: left;
`;

const SingleLesson = styled(Link)`
  display: flex;
  width: 300px;
  flex-direction: column;
  align-items: flex-start;
  padding: 18px;
  text-align: left;
  border-radius: 8px;
  box-shadow: 0px 7px 24px -10px rgba(2, 11, 28, 0.1);
  background-color: ${({ theme }) => theme.colors.backgroundLighter};
  margin-bottom: 32px;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.text};
  margin-right: 32px;
  transition: all 0.2s ease-in-out;
  border: 1px solid ${({ theme }) => theme.colors.backgroundLighter};

  img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 6px;
    margin-bottom: 16px;
  }
  h3 {
    font-size: 1.3rem;
    font-weight: 600;
    text-align: left;
    margin: 0;
    padding: 0;
  }
  p {
    opacity: 0.8;
    margin-top: 8px;
  }

  &:hover {
    scale: 1.01;
    box-shadow: 0px 7px 24px -10px rgba(2, 11, 28, 0.1);
    border: 1px solid ${({ theme }) => theme.colors.cta};
    background-color: ${({ theme }) => theme.colors.backgroundLighter};
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-right: 0;
  }
`;

const Tags = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Tag = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: 8px;
  opacity: 0.8;
  p {
    margin-left: 8px;
  }
`;

const AroundquestionContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;

  @media (max-width: 768px) {
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding-top: 12px;
    padding-bottom: 12px;
    h1 {
      width: 100px;
    }
  }
`;
