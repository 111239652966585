import React from "react";
import styled from "styled-components";

export function PrimaryButton({ children, onClick, disabled, className }) {
  return (
    <Button onClick={onClick} disabled={disabled} className={className}>
      {children}
    </Button>
  );
}

export function NeutralButton({ children, onClick, style, className }) {
  return (
    <NeutralButtonStyled onClick={onClick} style={style} className={className}>
      {children}
    </NeutralButtonStyled>
  );
}

export function BackButton({ onClick, children }) {
  return (
    <BackButtonStyled onClick={onClick}>
      <div>Previus</div>
      <h3>{children}</h3>
    </BackButtonStyled>
  );
}

export function NextButton({ onClick, children }) {
  return (
    <ForwardButtonStyled onClick={onClick}>
      <div>Next</div>
      <h3>{children}</h3>
    </ForwardButtonStyled>
  );
}

export function BlackButton({ onClick, children, style }) {
  return (
    <BlackButtonStyled onClick={onClick} style={style}>
      {children}
    </BlackButtonStyled>
  );
}

const Button = styled.button`
  background-color: ${({ theme }) => theme.colors.cta};
  color: ${({ theme }) => theme.colors.contrastText};
  border: 0;
  display: inline-block;
  padding: 12px 24px;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 0px;
  font-family: "Ubuntu", sans-serif;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const NeutralButtonStyled = styled.button`
  background-color: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.text};
  border: 0;
  display: inline-block;
  padding: 12px 24px;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  font-family: "Ubuntu", sans-serif;
`;

const BlackButtonStyled = styled.button`
  background-color: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.backgroundLighter};
  border: 0;
  display: inline-block;
  padding: 12px 24px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  font-size: 1rem;
  font-family: "Ubuntu", sans-serif;
`;

const BackButtonStyled = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.text};
  border: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 12px 24px;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  margin-right: 40px;
  border: 1px solid ${({ theme }) => theme.colors.background};
  transition: background-color 0.2s ease-in-out, border 0.4s ease-in-out;
  font-family: "Ubuntu", sans-serif;
  h3 {
    font-size: 1.2rem;
    font-weight: 400;
    margin: 0;
    margin-top: 8px;
    color: ${({ theme }) => theme.colors.cta};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.ctaLight};
    border: 1px solid ${({ theme }) => theme.colors.cta};
  }

  @media (max-width: 768px) {
    font-size: 0.9rem;
    padding: 12px;
    margin-right: 4px;
    font-size: 0.7rem;

    h3 {
      font-size: 0.9rem;
      text-align: left;
    }
  }
`;

const ForwardButtonStyled = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.text};
  border: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  font-family: "Ubuntu", sans-serif;
  padding: 12px 24px;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  margin-left: 40px;
  border: 1px solid ${({ theme }) => theme.colors.background};
  transition: background-color 0.2s ease-in-out, border 0.4s ease-in-out;
  h3 {
    font-size: 1.2rem;
    font-weight: 400;
    margin: 0;
    margin-top: 8px;
    color: ${({ theme }) => theme.colors.cta};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.ctaLight};
    border: 1px solid ${({ theme }) => theme.colors.cta};
  }
  @media (max-width: 768px) {
    font-size: 0.9rem;
    padding: 12px;
    margin-left: 4px;
    font-size: 0.7rem;

    h3 {
      font-size: 0.9rem;
      text-align: left;
    }
  }
`;
