import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import HeaderContainer from "../../Components/HeaderContainer";
import { PrimaryButton } from "../../Components/Buttons";
import jsonData from "../../localdb.json";

export default function RobotScript() {
  const [scripts, setScript] = useState(null);
  const [slug, setSlug] = useState(null);

  useEffect(() => {
    console.log("UseEffect");
    const tmpSlugs = window.location.pathname.split("/");
    jsonData.scripts.forEach((script) => {
      if (script.slug === tmpSlugs[2]) {
        setScript(script);
        setSlug(tmpSlugs[2]);
      }
    });
  }, []);

  // useEffect(() => {
  //   console.log("UseEffect");
  //   if (!scripts) return;
  //   const dataToPush = {
  //     id: scripts.id,
  //     type: "scripts",
  //     name: scripts.attributes.name,
  //     slug: window.location.pathname,
  //   };
  //   pushToLocalStorage(dataToPush);
  // }, [scripts]);

  const renderScripts = () => {
    return scripts.attributes.script.map((script) => {
      return (
        <SingleScriptContent key={script.id}>
          <h3>{script.title}</h3>
          <div dangerouslySetInnerHTML={{ __html: script.description }}></div>
          <PrimaryButton
            onClick={() => {
              window.open(
                `${script.script_file.data.attributes.url}`,
                "_blank"
              );
              // console.log(script.script_file.data.attributes.url);
            }}
          >
            Download
          </PrimaryButton>
        </SingleScriptContent>
      );
    });
  };

  return (
    <Container>
      {/* {console.log("lesson", lesson)}
      {console.log("tutorial", tutorial)}
      {console.log("content", content)}
      {console.log("backandsad", backAndForward)} */}
      {!scripts ? (
        <div>Loading...</div>
      ) : (
        <>
          <HeaderContainer>
            <Pagination>
              <Link to="/robot-scripts" className="back">
                Tutorials <span>{">"}</span>
              </Link>
              <Link to={`/robot-scripts/${slug}`}>
                {scripts.attributes.name}
              </Link>
            </Pagination>
            <h1>{scripts.attributes.name}</h1>
          </HeaderContainer>
          <LessonContent>
            <Content>{renderScripts()}</Content>
          </LessonContent>
        </>
      )}
    </Container>
  );
}

const Pagination = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  width: 100%;

  a {
    color: ${({ theme }) => theme.colors.text};
    font-weight: 400;
    text-decoration: none;
    margin: 0;
    padding: 0;

    &.back {
      margin-right: 4px;
      opacity: 0.8;
    }
  }
`;

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.backgroundLighter};
`;

const LessonContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding-bottom: 32px;
  video {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  p {
    text-align: left;
  }
  li {
    text-align: left;
    margin-bottom: 8px;
  }
  ul,
  ol {
    padding-left: 20px;
  }
  @media (max-width: 768px) {
    padding-left: 12px;
    padding-right: 12px;
  }
`;

const Content = styled.div`
  width: 100%;
  padding-top: 32px;
  padding-bottom: 32px;
  max-width: 900px;
  margin: 0 auto;
  img {
    width: 100%;
  }
  h1,
  h2,
  h3,
  h4 {
    text-align: left;
  }
`;

const SingleScriptContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 60px;
  button {
    margin-top: 16px;
  }
`;
